<template>
  <!-- :style="focus ? { backgroundColor: 'white' } : {}" -->
  <div id="message-send-box-wrapper" @drop="dropHandler">
    <div
      class="send-header-bar"
      v-if="custom.consultationStatus == 'WAIT_FOR_SERVER'"
    >
      <el-button type="success" plain size="small" @click="acceptOrder"
        >接诊</el-button
      >
      <el-button type="danger" size="small" plain @click="cancelOrder"
        >拒诊</el-button
      >
    </div>
    <div class="send-header-bar" v-if="custom.consultationStatus == 'SERVING'">
      <el-button
        type="primary"
        plain
        @click="sendCalling"
        size="small"
        v-if="custom.consultationType == '4'"
        >发起视频</el-button
      >
      <el-button type="primary" plain @click="handleSendImageClick" size="small"
        >选择图片</el-button
      >
      <el-popover
        placement="top"
        width="800"
        trigger="click"
        v-model="popVisible"
      >
        <div class="common-used" v-if="custom.consultationStatus == 'SERVING'">
          <h3>常用话术</h3>
          <div class="common-list">
            <el-tabs v-model="activeName">
              <el-tab-pane
                :label="comments[item].title"
                :name="comments[item].name"
                :key="index"
                v-for="(item, index) in Object.keys(comments)"
                :lazy="true"
              >
                <p
                  @click="chooseMsg(sing)"
                  v-for="(sing, ind) in comments[item].data"
                  :key="ind"
                >
                  {{ ind + 1 }}.{{ sing }}
                </p>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <el-button type="primary" plain slot="reference" size="small"
          >常用话术</el-button
        >
      </el-popover>

      <el-popconfirm
        title="是否确定结束问诊？"
        @confirm="completeOrder"
        confirmButtonText="确定"
        cancelButtonText="取消"
      >
        <template #reference>
          <el-button type="danger" plain size="small">结束问诊</el-button>
        </template>
      </el-popconfirm>
    </div>

    <div class="bottom">
      <textarea
        ref="text-input"
        rows="4"
        resize="false"
        v-model="messageContent"
        class="text-input"
        @focus="focus = true"
        @blur="focus = false"
        @keydown.enter.exact.prevent="handleEnter"
        @keyup.ctrl.enter.prevent.exact="handleLine"
        @keydown.up.stop="handleUp"
        @keydown.down.stop="handleDown"
        placeholder="请输入对话内容"
      >
      </textarea>
      <el-tooltip
        class="item"
        effect="dark"
        content="按Enter发送消息，Ctrl+Enter换行"
        placement="left-start"
      >
        <div class="btn-send" @click="sendTextMessage">
          <el-button
            type="primary"
            size="small"
            :disabled="custom.consultationStatus != 'SERVING'"
            >发送</el-button
          >
        </div>
      </el-tooltip>
    </div>
    <input
      type="file"
      id="imagePicker"
      ref="imagePicker"
      accept=".jpg, .jpeg, .png, .gif, .bmp"
      @change="sendImage"
      style="display: none"
    />

    <el-dialog :visible.sync="refuseVisibleRef" title="拒绝接诊" width="30%">
      <div v-for="item in refuseState.list" :key="item.id">
        <el-radio
          v-model="refuseState.refuseReason"
          :label="item.value"
          size="large"
          >{{ item.text }}</el-radio
        >
      </div>
      <el-input
        v-model="refuseState.otherRefuseReason"
        :disabled="refuseState.refuseReason != 'OTHER'"
        :rows="2"
        type="textarea"
        placeholder="请输入其他原因"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="refuseVisibleRef = false">取消</el-button>
          <el-button type="primary" @click="handelRefuseSure">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  acceptConsultation,
  refuseConsultation,
  completeConsultation,
} from '../../api/doctor';
import {
  Form,
  FormItem,
  Input,
  Dialog,
  Popover,
  RadioGroup,
  Radio,
  Tooltip,
  Rate,
} from 'element-ui';
import { answerConsultation } from '../../api/doctor';
export default {
  name: 'message-send-box',
  props: ['scrollMessageListToButtom'],
  components: {
    // callingMemberList: callingMemberList,
    ElInput: Input,
    ElForm: Form,
    ElFormItem: FormItem,
    ElDialog: Dialog,
    ElPopover: Popover,
    ElRadioGroup: RadioGroup,
    ElRadio: Radio,
    ElTooltip: Tooltip,
    ElRate: Rate,
  },
  data() {
    return {
      refuseState: {
        list: [
          {
            id: 1,
            value: 'OUT_OF_RANGE',
            text: '您的问诊超出范围，建议咨询其他医生',
          },
          {
            id: 2,
            value: 'INCONVENIENCE',
            text: '暂时不方便咨询，建议咨询其他医生',
          },
          { id: 3, value: 'OTHER', text: '其他' },
        ],
        refuseReason: 'OUT_OF_RANGE',
        otherRefuseReason: '',
        orderNo: '',
      },
      refuseVisibleRef: false,
      activeName: 'first',
      comments: {
        welcome: {
          name: 'first',
          title: '欢迎语',
          data: [
            '您好，请问有什么可以帮您？',
            '您好，我是您的在线医生，请问您有什么问题需要咨询？',
            '您好，请问您有什么需要帮助？',
            '您好，有什么可以帮您解答的吗？',
            '您好，请问您有什么问题需要解答？',
          ],
        },
        end: {
          name: 'second',
          title: '结束语',
          data: [
            '您好，请问您还有其他问题需要咨询吗？',
            '感谢您的咨询，本次问诊咨询服务已结束，祝您身体健康！',
            '您好，关于您反馈的病情情况，请问是否还有进一步的补充吗？',
            '您好，请问您还需要继续问诊吗？',
            '您好，由于您长时间未回复，先不打扰您了，稍后我这边会为您结束问诊，如您有需要可以再次进线联系医生。祝您身体健康！',
            '您好，请您核实一下相关问题后回复我们，本次问诊聊天框会为暂时您保留***分钟，请知悉。',
          ],
        },
        choose: {
          name: 'third',
          title: '开药阶段',
          data: [
            '您好，请问您当前所在的城市是在哪里？',
            '您好，您当前所在城市支持同城配送和普通快递两种配送方式。普通快递正常情况下1-3天可以送达，同城配送最快当天就可以送达，但是同城配送的费用可能要比普通快递高一些，请问您的药品配送方式是选择“普通快递”还是“同城配送”呢？',
            '您好，药品正常情况下1-3天可以送达，实际配送时效需要根据药品所属药库快递运力决定，我们医生无法为您加急或者给到您精确的配送时间，建议您及时留意快递信息。',
            '由于国内新冠疫情反复，结合您有咳嗽、发热症状，按照国家相关法规规定，暂无法为您卡线上开具相关药品。为了您和家人的健康，需立即到当地设有发热门诊的线下医疗机构就诊，完善新冠核酸检测、胸部CT等检查排查是否为新冠肺炎，祝您早日康复。',
          ],
        },
        // notmed: {
        //   name: 'fourth',
        //   title: '非医疗问题',
        //   data: [
        //     '您好，关于您咨询的问题，建议您联系保单页面上的众安客服热线（400-9999595）进行咨询，医生只负责解答您问诊方面的问题，谢谢您的理解与配合！',
        //     '您好，关于您咨询的问题，您可以联系好医保门诊险的客服进行咨询解答，医生只负责解答您问诊方面的问题，谢谢您的理解与配合！',
        //   ],
        // },
        cantdo: {
          name: 'fifth',
          title: '不合规或无法开具处方话术',
          data: [
            '您好，根据互联网医院相关政策法规，禁止给6岁以下儿童开具处方药，建议您去线下相关医院***科室就诊，做进一步的***检查，谢谢您的理解与配合。',
            '您好，根据您的描述，病人现在情况比较紧急，请病人立即到线下相关医院**科室去就诊，并做进一步的****检查，谢谢您的理解与配合。',
            '您好，根据互联网医院相关政策法规，禁止给用户开具麻醉药品、精神类药品处方以及其他用药风险较高、有其他特殊管理规定的药物，给您带来不便，敬请谅解。',
          ],
        },
        confirm: {
          name: 'sixth',
          title: '开药及确认阶段',
          data: [
            '您好，刚刚为您查询，目前药库暂无该药物，我们会向药库反馈，给您带来不便，非常抱歉。',
            '您好，药品处方一旦开具，将不能更改，请您知悉。',
            '您好，药品只是生产厂家不同，但效果是一样的。',
            '您好，我们合作的药房不一定有您的同款药品，但我们会尽量为您选择最为接近的药物。',
            '您好，经查询，系统显示药方已正常为您开具，如您无法在前端进一步操作，比如付款、购药、查询快递信息等，您可以联系好医保门诊险的客服进行咨询解答，谢谢您的理解与配合！',
            `您好，根据您描述的病例情况，医生将为您开具处方单，以下问题需要您确认：
                        请问您是否是为他人开药？
                        请问用药人既往是否有以下疾病：高血压、糖尿病、心脏病、脑梗死、消化系统疾病、肝功能异常、肾功能异常等疾病？
                        请问用药人是否有用药禁忌，比如：青霉素类、头孢类等药品？
                        请问用药人是否为女性特殊时期，比如：备孕期、孕期、哺乳期等？
                        请确认用药人是否具有以上特征？请问您是否有对此药物治疗哪些疾病有相关疑问？
                        如不包含上述问题并没有相关疑问，请回复“否”，如有疑问，请具体描述。`,
            '您好，请问用药人当前是有什么不适吗？请您详细描述一下。',
            '您好，该药品是用于治疗***疾病，请您结合用药人自身情况确认一下是否需要该药品呢？',
            '您好，如本次开药的药品是非本人使用，后期有可能存在无法理赔报销的问题，请问是否确定为您开具？',
            '您好，我们即将依据您的需求，开具药品处方，处方一旦开具，将不能更改，还请您知晓。',
            '根据国家相关法律和政策规定，线上药房禁止开具精神类药物处方。',
          ],
        },
      },
      commonList: [
        '请问您平时的血压、血糖、血脂情况怎么样？',
        '请问您去医院做过相关的检查吗？',
        '请问您平时饮食有什么偏好吗？',
        '您好，请问有什么可以帮到您？',
        '您客气了，这是我们应该做的，祝您早日康复。',
      ],
      popVisible: false,
      callingList: [],
      callingType: '',
      showCallingMember: false,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      messageContent: '',
      isSendCustomMessage: false,
      sendCustomDialogVisible: false,
      surveyDialogVisible: false,
      form: {
        data: '',
        description: '',
        extension: '',
      },
      rate: 5, // 评分
      suggestion: '', // 建议
      file: '',
      showAtGroupMember: false,
      atUserID: '',
      focus: false,
    };
  },
  computed: {
    ...mapGetters(['toAccount', 'currentConversationType']),
    ...mapState({
      memberList: (state) => state.group.currentMemberList,
      userID: (state) => state.user.userID,
      groupProfile: (state) =>
        state.conversation.currentConversation.groupProfile,
      currentConversation: (state) => state.conversation.currentConversation,
      custom: (state) => state.patient.custom,
      isBusy: (state) => {
        return state.video.isBusy;
      },
    }),
  },
  mounted() {
    this.$refs['text-input'].addEventListener('paste', this.handlePaste);
    this.$bus.$on('reEditMessage', this.reEditMessage);
    this.$bus.$on('getMessageContent', this.sendMessageContent);
    this.$bus.$on('setMessageContent', (data) => (this.messageContent = data));
  },
  beforeDestroy() {
    this.$refs['text-input'].removeEventListener('paste', this.handlePaste);
  },
  methods: {
    sendCalling() {
      if (this.isBusy) {
        this.$message({
          message: '不可重复发起',
          type: 'warning',
        });
        return;
      }
      let { appointment } = this.custom;
      const authTime = +new Date(
        `${appointment?.scheduleDate} ${appointment?.startTime}`.replace(
          /-/g,
          '/'
        )
      );
      const nowTime = +new Date();
      if (nowTime < authTime) {
        this.$message({
          message: '抱歉，暂未到患者预约时间，不可发起视频',
          type: 'warning',
        });
        return;
      }

      this.$store.commit('setCallingList', {
        memberList: [this.toAccount],
        type: 'C2C',
      });
      this.$bus.$emit(`video-call`);
    },
    async handelRefuseSure() {
      const { otherRefuseReason, refuseReason } = this.refuseState;

      if (refuseReason == 'OTHER' && !otherRefuseReason) {
        this.$message({
          type: 'warning',
          // message: '请上传后缀名为jpg、png、txt、pdf、doc、docx、xlsx、zip或rar的附件！'
          message: '请输入其他原因',
        });
        return;
      }
      const res = await refuseConsultation({
        orderNo: this.custom.orderNo,
        otherRefuseReason,
        refuseReason,
      });
      this.$bus.emit('changeOrderStatus', 'complete');
      this.refuseVisibleRef = false;
    },
    //结束问诊
    async completeOrder() {
      await completeConsultation({
        orderNo: this.custom.orderNo,
      });

      this.$bus.emit('changeOrderStatus', 'complete');
    },
    async acceptOrder() {
      const res = await acceptConsultation({
        orderNo: this.custom.orderNo,
        doctorId: this.custom.doctor.doctorId,
      });

      this.$bus.emit('changeOrderStatus', 'accept');
    },
    cancelOrder() {
      this.refuseVisibleRef = true;
    },
    sendMessageContent() {
      this.$bus.$emit('sendMessageContent', this.messageContent);
    },
    chooseMsg(sing) {
      this.popVisible = false;

      this.messageContent += sing;
    },
    reEditMessage(payload) {
      this.messageContent = payload;
    },

    handleUp() {
      const index = this.memberList.findIndex(
        (member) => member.userID === this.atUserID
      );
      if (index - 1 < 0) {
        return;
      }
      this.atUserID = this.memberList[index - 1].userID;
    },
    handleDown() {
      const index = this.memberList.findIndex(
        (member) => member.userID === this.atUserID
      );
      if (index + 1 >= this.memberList.length) {
        return;
      }
      this.atUserID = this.memberList[index + 1].userID;
    },
    handleEnter() {
      this.sendTextMessage();
    },
    handleLine() {
      this.messageContent += '\n';
    },
    handlePaste(e) {
      let clipboardData = e.clipboardData;
      let file;
      if (
        clipboardData &&
        clipboardData.files &&
        clipboardData.files.length > 0
      ) {
        file = clipboardData.files[0];
      }

      if (typeof file === 'undefined') {
        return;
      }
      // 1. 创建消息实例，接口返回的实例可以上屏
      let message = $tim.createImageMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          file: file,
        },
        onProgress: (percent) => {
          this.$set(message, 'progress', percent); // 手动给message 实例加个响应式属性: progress
        },
      });
      this.$store.commit('pushCurrentMessageList', message);

      // 2. 发送消息
      let promise = $tim.sendMessage(message);
      promise.catch((error) => {
        this.$store.commit('showMessage', {
          type: 'error',
          message: error.message,
        });
      });
    },
    dropHandler(e) {
      e.preventDefault();
      let file = e.dataTransfer.files[0];
      let message = {};
      if (file.type === 'video/mp4') {
        message = $tim.createVideoMessage({
          to: this.toAccount,
          conversationType: this.currentConversationType,
          payload: {
            file: file,
          },
          onProgress: (percent) => {
            this.$set(message, 'progress', percent); // 手动给message 实例加个响应式属性: progress
          },
        });
      } else {
        message = $tim.createFileMessage({
          to: this.toAccount,
          conversationType: this.currentConversationType,
          payload: {
            file: file,
          },
          onProgress: (percent) => {
            this.$set(message, 'progress', percent); // 手动给message 实例加个响应式属性: progress
          },
        });
      }
      this.$store.commit('pushCurrentMessageList', message);
      $tim
        .sendMessage(message)
        .then(() => {
          this.$refs.videoPicker.value = null;
        })
        .catch((imError) => {
          this.$store.commit('showMessage', {
            message: imError.message,
            type: 'error',
          });
        });
    },
    async sendTextMessage() {
      if (!this.currentConversation.conversationID) {
        this.$store.commit('showMessage', {
          message: '暂无对话',
          type: 'info',
        });
        return;
      }

      if (
        this.messageContent === '' ||
        this.messageContent.trim().length === 0
      ) {
        this.messageContent = '';
        this.$store.commit('showMessage', {
          message: '不能发送空消息哦！',
          type: 'info',
        });
        return;
      }

      const message = $tim.createTextMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: { text: this.messageContent },
        cloudCustomData: JSON.stringify({
          consultationOrderNo: this.custom.consultationOrderNo,
        }),
      });

      this.$store.commit('pushCurrentMessageList', message);
      this.$bus.$emit('scroll-bottom');

      try {
        await $tim.sendMessage(message);

        if (
          !this.custom.answerStatus &&
          ['1', '108'].includes(this.custom.consultationType)
        ) {
          await answerConsultation({
            // consultationOrderNo: this.custom.consultationOrderNo,

            // answerStatus: true,
            consultationOrderNo: this.custom.consultationOrderNo,
            orderNo: this.custom.orderNo,
          });
          this.$store.dispatch('updateCustom');
        }
      } catch (error) {
        this.$store.commit('showMessage', {
          type: 'error',
          message: error.message,
        });
      }

      this.messageContent = '';
    },

    random(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    handleSendImageClick() {
      if (!this.currentConversation.conversationID) {
        this.$store.commit('showMessage', {
          message: '暂无对话',
          type: 'info',
        });
        return;
      }
      this.$refs.imagePicker.click();
    },

    async sendImage() {
      const message = $tim.createImageMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          file: document.getElementById('imagePicker'), // 或者用event.target
        },
        onProgress: (percent) => {
          this.$set(message, 'progress', percent); // 手动给message 实例加个响应式属性: progress
        },
        cloudCustomData: JSON.stringify({
          consultationOrderNo: this.custom.consultationOrderNo,
        }),
      });
      this.$store.commit('pushCurrentMessageList', message);

      try {
        await $tim.sendMessage(message);
        this.$refs.imagePicker.value = null;
        if (
          !this.custom.answerStatus &&
          ['1', '108'].includes(this.custom.consultationType)
        ) {
          await answerConsultation({
            consultationOrderNo: this.custom.consultationOrderNo,
            orderNo: this.custom.orderNo,
          });
          this.$store.dispatch('updateCustom');
        }
      } catch (error) {
        this.$store.commit('showMessage', {
          message: imError.message,
          type: 'error',
        });
      }

      // $tim
      //   .sendMessage(message)
      //   .then(() => {
      //     this.$refs.imagePicker.value = null;
      //   })
      //   .catch((imError) => {
      //     this.$store.commit('showMessage', {
      //       message: imError.message,
      //       type: 'error',
      //     });
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dialog__body {
  & > div {
    margin-bottom: 10px;
  }
}
.common-used {
  // width: 343px;
  // height: 186px;
  // background: rgba(255, 255, 255, 0.55);
  // box-shadow: 0px 4px 8px 0px rgba(221, 221, 221, 0.5);

  h3 {
    line-height: 32px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #31b4b0;
    padding-left: 11px;
  }
  .common-list {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 28px;
    max-height: 250px;
    overflow-y: auto;
    cursor: pointer;

    p:hover {
      background: #ececec;
      padding-left: 11px;
    }
  }
}
</style>
<style lang="stylus" scoped>



#message-send-box-wrapper {
  box-sizing: border-box;
  overflow: hidden;
//   padding: 3px 20px 20px 20px;

}

.emojis {
  height: 160px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.emoji {
  height: 40px;
  width: 40px;
  box-sizing: border-box;
}

.send-header-bar {
  box-sizing: border-box;
  padding: 3px 0 0 0;
  background: #fff
  padding: 12px 17px;
  button{
    margin-right 16px
  }
  img{
    width:32px;
    height:32px;
    cursor:pointer;
    margin-right 16px
  }

}

.send-header-bar i {
  cursor: pointer;
  font-size: 24px;
  color: gray;
  margin: 0 12px 0 0;
}

.send-header-bar i:hover {
  color: $black;
}

textarea {
  resize: none;
}

.text-input {
  font-size: 16px;
  width: 100%;
  box-sizing: box-sizing;
  border: none;
  outline: none;
  background-color: transparent;
}

.block {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.bottom {
  padding-top: 10px;
  position: relative;
  padding: 18px 17px;
  height 190px
  .btn-send {
    cursor: pointer;
    position: absolute;
    color: $primary;
    font-size: 30px;
    right: 0;
    bottom: 17px;
    // padding: 6px 6px 4px 4px;
    // border-radius: 50%;

    img{
      display: block;
       width :32px;
      height :32px;
    }
  }
}

.group-live-icon-box:hover {
  .group-live-icon {
    z-index 1
  }
  .group-live-icon-hover{
    z-index 2
  }
}
.calling-member-list {
  position absolute
  top 50px
  background #fff
  margin-right 20px
  .calling-list-btn {
    width 140px
    display flex
    float right
    margin 10px 0
    .calling-btn {
      cursor pointer
      padding 6px 12px
      background #00A4FF
      color #ffffff
      font-size 14px
      border-radius 20px
      margin-left 13px
    }
  }
}
.el-popover {
  padding: 12px 0 0 0 !important;
}
</style>
